import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "Qui sommes nous ?",
      answer:
        "CreamDesign est une agence web spécialisée dans la création de sites internet modernes et efficaces. Nous offrons des services de conception, de développement et d'optimisation de sites web pour répondre aux besoins de nos clients. Notre équipe de professionnels expérimentés travaille en étroite collaboration avec nos clients pour concevoir des sites web qui correspondent à leur image de marque et à leurs objectifs commerciaux. Nous offrons également des services de référencement naturel (SEO) pour aider nos clients à améliorer leur visibilité en ligne et à atteindre leur public cible. Contactez-nous dès maintenant pour en savoir plus sur la manière dont CreamDesign peut vous aider à créer un site web réussi et à améliorer votre présence en ligne.",
    },
    {
      question: "Qu'est qu'on propose ?",
      answer:
        "Notre entreprise offre une gamme complète de services de qualité pour répondre aux besoins de nos clients. Nous sommes spécialisés dans la conception et le développement de sites web modernes et attrayants, adaptés à tous les types d'entreprises et à tous les budgets. Nous offrons également des services de référencement naturel (SEO) pour aider nos clients à améliorer leur visibilité en ligne et à atteindre leur public cible. Nous sommes également en mesure de proposer des services de marketing numérique complets pour aider nos clients à atteindre leurs objectifs commerciaux, tels que la gestion de campagnes publicitaires sur les réseaux sociaux, l'optimisation des taux de conversion et l'analyse des données. Notre équipe de professionnels qualifiés est à votre disposition pour vous offrir des solutions personnalisées et adaptées à vos besoins spécifiques. Contactez-nous dès maintenant pour en savoir plus sur la manière dont nous pouvons vous aider à améliorer votre présence en ligne et à atteindre vos objectifs commerciaux.",
    },
    {
      question: "Simple process for workflow?",
      answer:
        "Iisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Mutat tacimates id sit. Ridens mediocritatem ius an, eu nec magna imperdiet.",
    },
    {
      question: "What is CreamDesign? a Multi-purpose template?",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.",
    },
    {
      question: "Why responsive one page template?",
      answer:
        "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.",
    },
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-2">FAQ</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Avez-vous des questions ?
            </h2>
            {/* Heading end*/}

            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/faq.png"
              title="FAQ"
              alt="faq"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
