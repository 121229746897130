import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">A propose de nous</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Nous connaître
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hello, je suis{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                CreamDesign
              </span>
            </h2>
            <p className="text-5">
              En tant qu'agence de design et de développement web,
              nous sommes passionnés par la création de sites web simples, propres et élégants
              qui offrent une véritable valeur ajoutée à l'utilisateur final.
              Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins et leurs objectifs,
              et nous nous engageons à respecter les délais et le budget convenus pour chaque projet.
              Notre objectif est de fournir un service de qualité et de garantir que chaque site web que
              nous créons répond aux normes les plus élevées en matière de design et de développement.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  15
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Experiance</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">Luca Di Fazio</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:contact@creamdesign.fr">
                contact@creamdesign.fr
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Année d'experience</p>
            <p className="text-4 text-dark fw-600 mb-0">Depuis 2008</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Paris, France.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
